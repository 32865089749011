<template>
  <div>
    <vee-form :validation-schema="addressSchema">
      <!-- map -->
      <div>
        <ion-grid class="ion-no-padding">
          <ion-list mode="ios" lines="none">
            <div class="title">{{ $t('customerPage.business_information') }}</div>
            <!-- StartCompanyName -->
            <div>
              <ion-item
                :class="[
                  'px-4',
                  form.companyName.isError
                    ? 'ion-item-danger'
                    : !form.companyName.isError && form.companyName.value
                    ? ''
                    : ''
                ]"
              >
                <ion-label
                  class="text-title"
                  :color="
                    form.companyName.isError
                      ? 'danger'
                      : !form.companyName.isError && form.companyName.value
                      ? ''
                      : 'tertiary'
                  "
                  position="stacked"
                  >{{ $t('customerPage.company_name') }}</ion-label
                >
                <ion-input
                  :placeholder="$t('customerPage.enter_new_company_name')"
                  ref="companyName"
                  type="text"
                  v-model.trim="form.companyName.value"
                  @ionInput="handleValidateForm(1)"
                ></ion-input>
              </ion-item>
              <ion-text v-if="form.companyName.isError">
                <div class="px-4 py-1 fs-12px text-danger">
                  {{
                    $t(
                      `${
                        form.companyName.value.length === 0
                          ? 'customerPage.company_name_cannot_be_empty'
                          : 4 > form.companyName.value.length
                          ? 'customerPage.company_name_must_be_at_least_4_characters'
                          : form.companyName.errorMsg
                          ? form.companyName.errorMsg
                          : ''
                      }`
                    )
                  }}
                </div>
              </ion-text>
            </div>
            <!-- EndCompanyName -->

            <!-- StartCustomerProfile -->
            <div>
              <ion-item
                @click="setOpenModalProfile(true)"
                :class="[
                  'pl-4 mt-2 set-height',
                  form.profile.isError
                    ? 'ion-item-danger'
                    : !form.profile.isError && form.profile.value
                    ? ''
                    : ''
                ]"
              >
                <div class="select-profile">
                  <ion-text
                    class="ion-mb text-title"
                    :color="
                      form.profile.isError
                        ? 'danger'
                        : !form.profile.isError && form.profile.value
                        ? ''
                        : 'tertiary'
                    "
                  >
                    {{ $t('profile') }}
                  </ion-text>
                  <div class="row mb-2">
                    <div :class="['profile-name', !form.profile.value ? 'off-profile' : '']">
                      {{ form.profile.value ? form.profile.value : $t('customerPage.select_a_profile') }}
                    </div>
                    <ion-icon class="caret-down" :icon="caretDown"></ion-icon>
                  </div>
                </div>
              </ion-item>
              <ion-text>
                <div v-if="form.profile.isError" class="px-4 py-1 fs-12px text-danger">
                  {{ $t('customerPage.profile_cannot_be_empty') }}
                </div>
              </ion-text>
            </div>
            <!-- EndCustomerProfile -->
            <div class="ion-margin-top">
              <ion-label class="px-4 title-address" position="stacked">{{
                $t('customerPage.address')
              }}</ion-label>
            </div>

            <div class="address-form" v-for="(data, index) in sortDefaultAddress" :key="index">
              <div class="address" @click="handleOpenModalUpdateAddress(data)">
                <div class="titles">
                  <ion-text>{{ $t('customerPage.address') }} {{ index + 1 }}</ion-text>
                  <ion-text v-if="data.is_default" color="primary">{{
                    $t('default_billing_address')
                  }}</ion-text>
                </div>
                <div class="contents mb-1">
                  <ion-text>{{ getAddressFormat(data) }}</ion-text>
                </div>
              </div>
              <div class="btn-as-default">
                <ion-button
                  v-if="!data.is_default"
                  mode="md"
                  fill="outline"
                  @click="handleSetDefaultAddress(data)"
                  >{{ $t('set_as_default_address') }}</ion-button
                >
              </div>
            </div>

            <div class="btn-add-address">
              <ion-button @click="handleOpenModalAddAddress" mode="md"
                ><ion-icon :icon="addOutline"></ion-icon> {{ $t('add_address') }}</ion-button
              >
            </div>

            <!-- start additional options b2b -->
            <div class="ion-margin-top">
              <ion-item class="px-4 ion-no-border">
                <ion-label position="stacked" color="tertiary" class="fw-500 fs-3">
                  {{ $t('additional_options') }}
                </ion-label>
                <div class="d-flex btn-toggle">
                  <ion-toggle :checked="form.halal" @ionChange="handleAdditional(1)"> </ion-toggle>
                  <div class="btn-toggle-text">
                    <ion-item>
                      <ion-label>{{ $t('halal') }}</ion-label>
                    </ion-item>
                    <div>
                      <div class="text-description" v-if="form.halal === false">
                        {{ $t('halal_off') }}
                      </div>
                      <div class="text-description" v-if="form.halal === true">
                        {{ $t('halal_on') }}
                      </div>
                    </div>
                  </div>
                </div>
              </ion-item>
            </div>
            <!-- end additional options -->
            <!-- start btn -->
            <div class="btn-form">
              <ion-button
                :disabled="disableSave"
                mode="md"
                @click="handleUpdateBusinessInfomation"
                class="mt-5 fw-500"
                >{{ $t('save') }}</ion-button
              >
            </div>
            <!-- end btn -->
          </ion-list>
        </ion-grid>
      </div>
    </vee-form>
  </div>
  <ion-modal :is-open="isOpenModalAddress" mode="md">
    <ion-page>
      <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar>
          <ion-buttons slot="start" hide-back-button="true">
            <ion-back-button text="" @click="setOpenModalAddress(false)" default-href="/"></ion-back-button>
          </ion-buttons>
          <ion-title>{{ titleAddAddress || $t('newcustomer.update_address') }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content :scroll-events="true" @ionScroll="handleScroll($event)">
        <ion-grid class="ion-no-padding">
          <ion-list mode="ios" lines="none">
            <!-- input search -->
            <div class="ion-margin-top">
              <ion-label class="px-4 title-address" position="stacked">{{
                $t('customerPage.address')
              }}</ion-label>

              <div :class="['px-4', 'address-search', addressCss.length !== 0 ? '' : '']">
                <ion-label
                  class="text-title"
                  :color="addressCss.length !== 0 ? '' : 'tertiary'"
                  position="stacked"
                  >{{ $t('search') }} {{ $t('customerPage.address') }}</ion-label
                >
                <div class="btn-search mt-2">
                  <input type="text" ref="searchInput" :placeholder="$t('enter_address')" />
                </div>
              </div>
            </div>

            <!-- gooogle map -->
            <div>
              <div class="mt-4" ref="mapDiv" style="width: 100%; height: 40vh" />
            </div>

            <!-- end gooogle map -->
            <!-- city and postalCode -->
            <div class="ion-margin-top">
              <ion-row>
                <!-- city -->
                <ion-col size="6" class="pr-1 pb-0">
                  <ion-item
                    :class="[
                      'px-4',
                      form.country.isError
                        ? 'ion-item-danger'
                        : !form.country.isError && form.country.value
                        ? ''
                        : ''
                    ]"
                  >
                    <ion-label
                      class="text-title"
                      :color="
                        form.country.isError
                          ? 'danger'
                          : !form.country.isError && form.country.value
                          ? ''
                          : 'tertiary'
                      "
                      position="stacked"
                    >
                      {{ $t('country') }}
                    </ion-label>
                    <ion-input
                      disabled
                      v-model.trim="form.country.value"
                      name="country"
                      :placeholder="$t('enter_country')"
                      type="text"
                      @ionInput="handleValidateForm(6)"
                    >
                    </ion-input>
                  </ion-item>
                </ion-col>

                <!-- postal code -->
                <ion-col size="6" class="pl-1 pb-0">
                  <ion-item
                    :class="[
                      'px-4',
                      form.state.isError
                        ? 'ion-item-danger'
                        : !form.state.isError && form.state.value
                        ? ''
                        : ''
                    ]"
                  >
                    <ion-label
                      class="text-title"
                      :color="
                        form.state.isError
                          ? 'danger'
                          : !form.state.isError && form.state.value
                          ? ''
                          : 'tertiary'
                      "
                      position="stacked"
                    >
                      {{ $t('state') }}
                    </ion-label>
                    <ion-input
                      v-model.trim="form.state.value"
                      name="state"
                      type="text"
                      @ionInput="handleValidateForm(7)"
                      :placeholder="$t('enter_state')"
                    >
                    </ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="6">
                  <ion-text>
                    <div v-if="form.country.isError" class="px-4 py-1 fs-12px text-danger">
                      {{ $t('address_form.country.required') }}
                    </div>
                  </ion-text>
                </ion-col>
                <ion-col size="6">
                  <ion-text>
                    <div v-if="form.state.isError" class="px-4 py-1 fs-12px text-danger">
                      {{ $t('address_form.state.required') }}
                    </div>
                  </ion-text>
                </ion-col>
              </ion-row>
            </div>

            <!-- city and postalCode -->
            <div class="ion-margin-top">
              <ion-row>
                <!-- city -->
                <ion-col size="6" class="pr-1 pb-0">
                  <ion-item
                    :class="[
                      'px-4',
                      form.city.isError ? 'ion-item-danger' : !form.city.isError && form.city.value ? '' : ''
                    ]"
                  >
                    <ion-label
                      class="text-title"
                      :color="
                        form.city.isError ? 'danger' : !form.city.isError && form.city.value ? '' : 'tertiary'
                      "
                      position="stacked"
                    >
                      {{ $t('city') }}
                    </ion-label>
                    <ion-input
                      v-model.trim="form.city.value"
                      name="city"
                      :placeholder="$t('customerPage.enter_city')"
                      type="text"
                      @ionInput="handleValidateForm(3)"
                    >
                    </ion-input>
                  </ion-item>
                </ion-col>

                <!-- postal code -->
                <ion-col size="6" class="pl-1 pb-0">
                  <ion-item
                    :class="[
                      'px-4',
                      form.postalCode.isError
                        ? 'ion-item-danger'
                        : !form.postalCode.isError && form.postalCode.value
                        ? ''
                        : ''
                    ]"
                  >
                    <ion-label
                      class="text-title"
                      :color="
                        form.postalCode.isError
                          ? 'danger'
                          : !form.postalCode.isError && form.postalCode.value
                          ? ''
                          : 'tertiary'
                      "
                      position="stacked"
                    >
                      {{ $t('postal_code') }}
                    </ion-label>
                    <ion-input
                      v-model.trim="form.postalCode.value"
                      name="postalCode"
                      type="number"
                      inputmode="numeric"
                      @ionInput="handleValidateForm(4)"
                      :placeholder="$t('customerPage.enter_postal_code')"
                      onkeydown="if(event.key==='.'){event.preventDefault();}"
                      oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                    >
                    </ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="6">
                  <ion-text>
                    <div v-if="form.city.isError" class="px-4 py-1 fs-12px text-danger">
                      {{ $t('address_form.city.required') }}
                    </div>
                  </ion-text>
                </ion-col>
                <ion-col size="6">
                  <ion-text>
                    <div v-if="form.postalCode.isError" class="px-4 py-1 fs-12px text-danger">
                      {{ $t('address_form.postal_code.required') }}
                    </div>
                  </ion-text>
                </ion-col>
              </ion-row>
            </div>
            <!-- end city and postal code -->
            <!-- street-->

            <div class="ion-margin-top">
              <ion-item
                :class="[
                  ' px-4',
                  form.street.isError
                    ? 'ion-item-danger'
                    : !form.street.isError && form.street.value
                    ? ''
                    : ''
                ]"
              >
                <ion-label
                  :color="
                    form.street.isError
                      ? 'danger'
                      : !form.street.isError && form.street.value
                      ? ''
                      : 'tertiary'
                  "
                  class="text-title"
                  position="stacked"
                >
                  {{ $t('street') }}
                </ion-label>
                <ion-input
                  v-model.trim="form.street.value"
                  name="road"
                  :placeholder="$t('customerPage.enter_street_address')"
                  type="text"
                  :disabled="true"
                  @ionInput="handleValidateForm(5)"
                ></ion-input>
              </ion-item>
              <ion-text v-if="form.street.isError">
                <div class="px-4 py-1 fs-12px text-danger">
                  {{ $t('address_form.road.required') }}
                </div>
              </ion-text>
            </div>
            <!--end street-->
            <!-- building name  -->
            <div class="ion-margin-top">
              <ion-item :class="['px-4', form.building.value?.length !== 0 ? '' : '']">
                <ion-label
                  :color="form.building.value?.length !== 0 ? '' : 'tertiary'"
                  class="text-title"
                  position="stacked"
                >
                  {{ $t('building_name') }}
                </ion-label>
                <ion-input
                  v-model="form.building.value"
                  name="building"
                  :placeholder="$t('customerPage.enter_building_name')"
                  type="text"
                ></ion-input>
              </ion-item>
            </div>
            <!-- end building name  -->
            <!-- floor - unit -->
            <div class="ion-margin-top">
              <ion-row>
                <ion-col size="6" class="pr-1 pb-0">
                  <ion-item
                    :class="form.floorNumber.value?.length !== 0 ? '' : ''"
                    class="px-4 fs-2 custom-item"
                  >
                    <ion-label
                      :color="form.floorNumber.value?.length !== 0 ? '' : 'tertiary'"
                      position="stacked"
                    >
                      {{ $t('floorNo') }}
                    </ion-label>
                    <ion-input
                      v-model.trim="form.floorNumber.value"
                      name="floorNumber"
                      :placeholder="$t('customerPage.enter_floor_no')"
                      type="text"
                    ></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col size="6" class="pl-1 pb-0">
                  <ion-item :class="form.unit.value?.length !== 0 ? '' : ''" class="px-4 fs-2 custom-item">
                    <ion-label :color="form.unit.value?.length !== 0 ? '' : 'tertiary'" position="stacked"
                      >{{ $t('unitNo') }}
                    </ion-label>
                    <ion-input
                      v-model.trim="form.unit.value"
                      name="unit"
                      :placeholder="$t('customerPage.enter_unit_no')"
                      type="text"
                    ></ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
            </div>
            <!--end floor - unit -->
            <!--start stall -->
            <div class="ion-margin-top">
              <ion-item :class="['px-4', form.stall.value?.length !== 0 ? '' : '']">
                <ion-label :color="form.stall.value?.length !== 0 ? '' : 'tertiary'" position="stacked">
                  {{ $t('stallNo') }}
                </ion-label>
                <ion-input
                  v-model.trim="form.stall.value"
                  name="stall"
                  :placeholder="$t('customerPage.enter_stall_no')"
                >
                </ion-input>
              </ion-item>
            </div>
            <!--end stall -->
            <div class="btn-form-add-address">
              <ion-button
                class="mt-5 fw-500"
                style="box-shadow: unset"
                fill="clear"
                mode="md"
                v-bind="propsDeleteAddressBtn()"
                @click="handleRemoveAddress"
              >
                <ion-icon v-if="!isDisableBtnRemove" slot="start" :icon="trashOutline"></ion-icon>
                <ion-icon v-if="isDisableBtnRemove" slot="start" :icon="informationCircleOutline"></ion-icon>
                {{ $t('address_btn_remove') }}
              </ion-button>
              <ion-button
                :disabled="isDisableBtn"
                v-if="!titleAddAddress"
                mode="md"
                @click="handleUpdateAddress"
                class="mt-5 fw-500"
                >{{ $t('save') }}</ion-button
              >
              <ion-button
                :disabled="isDisableBtn"
                v-if="titleAddAddress"
                mode="md"
                @click="handleAddAddress"
                class="mt-5 fw-500"
                >{{ $t('add_address') }}</ion-button
              >
            </div>
          </ion-list>
        </ion-grid>
      </ion-content>
    </ion-page>
  </ion-modal>
  <ion-modal
    ref="modal"
    :initial-breakpoint="1"
    :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
    mode="ios"
    css-class="bottom-sheet-modal"
    :is-open="isOpenSelectProfile"
    @didDismiss="setOpenModalProfile(false)"
  >
    <CustomerProfile
      @closeModal="setOpenModalProfile(false)"
      @selectProfile="setBusinessProfile"
      :businessProfile="customerProfilesData"
      :defaultProfile="form.profile.id"
    />
  </ion-modal>
  <ion-loading
    mode="ios"
    :is-open="isOpenRef"
    cssClass="my-custom-class"
    :message="`${$t('please_wait')}. . .`"
    @didDismiss="setOpen(false)"
  >
  </ion-loading>
</template>
<script>
/* eslint-disable no-undef */
import checkbox from '@/assets/images/checkbox.png';
import CustomerProfile from '@/components/customer-profile/CustomerProfile.vue';
import { apolloClient } from '@/main';
import {
  addAddress,
  getCustomerProfiles,
  removeAddress,
  updateAddress,
  updateCustomer
} from '@/modules/b2b/services/graphql';
import { addressSchema, getDataFromPlace, setUpMap } from '@/modules/b2b/services/libs/address';
import { getAddressFormat } from '@/modules/b2b/services/libs/helper';
import { ACTIONS } from '@/modules/b2b/store/customer/actions';
import '@/modules/b2b/views/customer/components/styles/AddressForm.scss';
import '@/modules/b2b/views/customer/components/styles/VueTelInput.scss';
import { presentAlert } from '@/modules/shared/utils';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { Loader } from '@googlemaps/js-api-loader';
import { alertController } from '@ionic/vue';
import {
  addOutline,
  caretDown,
  closeOutline,
  informationCircleOutline,
  searchOutline,
  trashOutline
} from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { Form as VeeForm, useForm } from 'vee-validate';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('b2b/customer');

export default defineComponent({
  name: 'AddressForm',
  components: {
    VeeForm,
    CustomerProfile
  },
  props: ['customerDetailsEdit'],
  emits: ['handle-rerender-component'],
  inject: ['$storage'],
  data() {
    return {
      isOpenRef: false,
      dataCheckDuplicateCustomer: [],
      addressCss: '',
      titleAddAddress: '',
      addressId: '',
      country_id: '',
      customerProfilesData: [],
      form: {
        companyName: {
          isError: false,
          value: '',
          errorMsg: ''
        },
        profile: {
          isError: false,
          id: null,
          value: ''
        },
        country: {
          isError: false,
          value: ''
        },
        state: {
          isError: false,
          value: ''
        },
        city: {
          isError: false,
          value: ''
        },
        postalCode: {
          isError: false,
          value: ''
        },
        street: {
          isError: false,
          value: ''
        },
        building: {
          value: ''
        },
        floorNumber: {
          value: ''
        },
        unit: {
          value: ''
        },
        stall: {
          value: ''
        },
        halal: false
      }
    };
  },
  setup() {
    const user = ref(null);
    const selectedCompany = ref(null);
    const selectCustomer = ref(null);
    const input = ref(null);
    const placeData = ref(null);
    const latlng = ref(null);
    const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
    const loader = new Loader({ apiKey: key, libraries: ['places'] });
    const isOpenModalAddress = ref(false);
    const setOpenModalAddress = async (state) => (isOpenModalAddress.value = state);
    const isOpenSelectProfile = ref(false);
    // Create a form context with the validation schema
    const { errors, validate, values: address } = useForm({
      validationSchema: addressSchema
    });
    const handleScroll = (event) => {
      if (event && Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    };
    return {
      isOpenModalAddress,
      setOpenModalAddress,
      isOpenSelectProfile,
      handleScroll,
      user,
      selectedCompany,
      selectCustomer,
      getAddressFormat,
      loader,
      input,
      placeData,
      latlng,
      // form info
      addressSchema,
      errors,
      validate,
      address,
      // icon
      searchOutline,
      closeOutline,
      checkbox,
      addOutline,
      caretDown,
      trashOutline,
      informationCircleOutline
    };
  },
  watch: {
    'form.profile.value'() {
      if (this.form.profile.value) {
        this.form.profile.isError = false;
      }
    },
    'form.companyName.value'() {
      const regex = /^[\p{L}\d\s$!()?.'"@&+%,-]+$/u;
      if (this.form.companyName.value && !regex.test(this.form.companyName.value)) {
        this.form.companyName.isError = true;
        this.form.companyName.errorMsg = `${this.$t('only_allowed_symbol')} $ ! ( ) ? - . ' " @ & + % ,`;
        //becuase it will auto remove the invalid symbol and char, so we need to give user time to see the error meesage
        setTimeout(() => {
          this.form.companyName.value = this.form.companyName.value.replace(
            /[^\p{L}\d\s$!()?.'"@&+%,-]+/gu,
            ''
          );
          this.form.companyName.isError = false;
          this.form.companyName.errorMsg = '';
        }, 2000);
      }
    }
  },
  computed: {
    sortDefaultAddress() {
      let address = this.customerDetailsEdit.addresses;
      const data = address.find((items) => items.is_default === true);
      if (data) {
        address.splice(address.indexOf(data), 1);
        address.unshift(data);
        return address;
      } else {
        return address;
      }
    },
    isDisableBtn() {
      return !this.latlng;
    },
    isDisableBtnRemove() {
      let address = this.customerDetailsEdit.addresses.find((items) => items.id === this.addressId);
      return address.is_default;
    },
    disableSave() {
      return !this.isDisableBtn || this.form.companyName.isError;
    }
  },
  async mounted() {
    this.handleGetDataBusinessInfomation();
    await this.handleGetCustomerProfile();
    this.user = await this.$storage.getUser();
    this.selectedCompany = await this.$storage.getSelectedCompany();
    this.country = this.user.country.description;
  },
  methods: {
    ...mapActions([ACTIONS.GET_CUSTOMER_PROFILE]),
    handleGetDataBusinessInfomation() {
      this.form.companyName.value = this.customerDetailsEdit.name || this.customerDetailsEdit.branch || '';
      this.form.profile.value = this.customerDetailsEdit.profile || '';
      this.form.profile.id = this.customerDetailsEdit.profile_id;
      this.form.halal = this.customerDetailsEdit.halal_products;
    },
    async handleGetCustomerProfile() {
      try {
        const { data } = await apolloClient.query({
          query: getCustomerProfiles
        });
        this.customerProfilesData = data.getCustomerProfiles;
      } catch (error) {
        console.log(error);
      }
    },
    async setOpenModalProfile(params) {
      this.isOpenSelectProfile = params;
    },
    setBusinessProfile(selectedProfile) {
      this.form.profile.value = selectedProfile.name;
      this.form.profile.id = selectedProfile.id;
    },
    async handleGetDataUpdate(params) {
      this.form.country.value = this.user.country.description;
      this.form.state.value = params?.state || '';
      this.form.city.value = params?.city || '';
      this.form.postalCode.value = params?.postal_code || '';
      this.form.street.value = params?.road || '';
      this.form.building.value = params?.building || '';
      this.form.floorNumber.value = params?.floor_number || '';
      this.form.unit.value = params?.unit || '';
      this.form.stall.value = params?.stall || '';
      this.latlng = params?.latlng;
      this.handleResetValidate();
    },
    async handleOpenModalUpdateAddress(params) {
      this.addressId = params.id;
      this.country_id = params.country_id;
      this.titleAddAddress = null;
      await this.handleGetDataUpdate(params);
      await this.setOpenModalAddress(true);
      await this.handleSetupMap();
    },
    async handleSetupMap() {
      await this.loader.load();
      setTimeout(async () => {
        this.input = this.$refs.searchInput;
        await setUpMap({
          defaultCenter: this.latlng,
          input: this.input,
          user: this.user,
          mapDiv: this.$refs.mapDiv,
          callback: this.getDataFromPlaceFn
        });
      }, 200);
    },
    async handleOpenModalAddAddress() {
      this.titleAddAddress = this.$t('add_address');
      await this.handleGetDataUpdate(null);
      await this.setOpenModalAddress(true);
      await this.handleSetupMap();
    },
    async setOpen(params) {
      this.isOpenRef = params;
    },
    validateForm(params) {
      if (params === 1) {
        const lengthCompanyName = this.form.companyName.value.length;
        lengthCompanyName === 0 || lengthCompanyName < 4
          ? (this.form.companyName.isError = true)
          : (this.form.companyName.isError = false);
        const regexSpecialChar = /^[\p{L}\d\s$!()?.'"@&+%,-]+$/u;
        const validateText = regexSpecialChar.test(this.form.companyName.value);
        if (!validateText) {
          this.form.companyName.isError = true;
          this.form.companyName.errorMsg = `${this.$t('only_allowed_symbol')} $ ! ( ) ? - . ' " @ & + % ,`;
        }
      } else if (params === 2) {
        this.form.profile.value.length === 0
          ? (this.form.profile.isError = true)
          : (this.form.profile.isError = false);
      } else if (params === 3) {
        this.form.city.value.length === 0
          ? (this.form.city.isError = true)
          : (this.form.city.isError = false);
      } else if (params === 4) {
        const length = this.form.postalCode.value.length;
        length === 0
          ? (this.form.postalCode.isError = true)
          : length >= 5
          ? (this.form.postalCode.isError = false)
          : '';
      } else if (params === 5) {
        this.form.street.value.length === 0
          ? (this.form.street.isError = true)
          : (this.form.street.isError = false);
      } else if (params === 6) {
        this.form.country.value.length === 0
          ? (this.form.country.isError = true)
          : (this.form.country.isError = false);
      } else {
        this.form.state.value.length === 0
          ? (this.form.state.isError = true)
          : (this.form.state.isError = false);
      }
    },
    handleAdditional(params) {
      if (params === 1) {
        this.form.halal = !this.form.halal;
      }
    },
    handleValidateForm: debounce(function (params) {
      this.validateForm(params);
    }, 1000),
    async handleSetDefaultAddress(params) {
      try {
        await this.setOpen(true);
        await apolloClient.mutate({
          mutation: updateAddress,
          variables: {
            addressId: params.id,
            payload: {
              countryId: params.country_id || '',
              state: params.state || '',
              city: params.city || '',
              floorNumber: params.floor_number || '',
              road: params.road || '',
              building: params.building || '',
              postalCode: params.postal_code || '',
              unit: params.unit || '',
              stall: params.stall || '',
              latlng: params.latlngs,
              supplierId: this.customerDetailsEdit.id,
              isDefault: true
            }
          }
        });
        await this.setOpen(false);
        this.$storage.setSelectedDeliveryAddress(params.id);
        this.$storage.setSelectedBillingAddress(params.id);
        this.$emit('handle-rerender-component', true);
      } catch (e) {
        this.presentAlert(e);
      }
    },
    async handleUpdateAddress() {
      await this.setOpen(true);
      try {
        const isUpdateAddress = this.handleValidateFormSubmit([3, 4, 5, 6, 7]);
        if (isUpdateAddress) {
          await apolloClient.mutate({
            mutation: updateAddress,
            variables: {
              addressId: this.addressId,
              payload: {
                countryId: this.country_id || '',
                state: this.form.state.value || '',
                city: this.form.city.value || '',
                floorNumber: this.form.floorNumber.value || '',
                road: this.form.street.value || '',
                building: this.form.building.value || '',
                postalCode: this.form.postalCode.value || '',
                unit: this.form.unit.value || '',
                stall: this.form.stall.value || '',
                latlng: this.latlng || {},
                supplierId: this.customerDetailsEdit.id
              }
            }
          });
          await this.setOpen(false);
          await this.setOpenModalAddress(false);
          this.$emit('handle-rerender-component', true);
        }
        await this.setOpen(false);
      } catch (e) {
        this.presentAlert(e);
      }
    },
    propsDeleteAddressBtn() {
      const address = this.customerDetailsEdit.addresses.find((items) => items.id === this.addressId);
      let props = {
        color: 'danger'
      };
      if (address.is_default) {
        props.color = 'medium';
      }
      return props;
    },
    async handleRemoveAddress() {
      try {
        let address = this.customerDetailsEdit.addresses.find((items) => items.id === this.addressId);
        if (address.is_default) throw new Error(this.$t('address_unable_delete_default'));
        await presentAlert({
          mode: 'ios',
          message: this.$t('address_remove_confirm'),
          buttons: [
            {
              text: 'Ok',
              handler: async () => {
                apolloClient
                  .mutate({
                    mutation: removeAddress,
                    variables: {
                      addressId: this.addressId
                    }
                  })
                  .then(async () => {
                    await this.setOpenModalAddress(false);
                    await this.setOpen(false);
                    this.$emit('handle-rerender-component', true);
                  });
              }
            },
            {
              text: 'Cancel'
            }
          ]
        });
      } catch (e) {
        presentAlert({
          mode: 'ios',
          header: '',
          message: '<img src="/assets/icon/payment/info-circle.svg" /><p/>' + e.message,
          buttons: ['OK']
        });
      }
    },
    async handleAddAddress() {
      try {
        const isAddAddress = this.handleValidateFormSubmit([3, 4, 5, 6, 7]);
        if (isAddAddress) {
          if (!this.latlng) {
            return;
          } else {
            await this.setOpen(true);
            await apolloClient.query({
              query: addAddress,
              variables: {
                state: this.form.state.value,
                floorNumber: this.form.floorNumber.value,
                road: this.form.street.value,
                building: this.form.building.value,
                unit: this.form.unit.value,
                stall: this.form.stall.value,
                city: this.form.city.value,
                postalCode: `${this.form.postalCode.value}`,
                latlng: this.latlng || {},
                countryId: +this.user.country.id,
                address_type_id: 1,
                supplierId: +this.customerDetailsEdit.id,
                addressTypeId: 1
              }
            });
            await this.setOpenModalAddress(false);
            await this.setOpen(false);
            this.$emit('handle-rerender-component', true);
          }
        }
        await this.setOpen(false);
      } catch (e) {
        this.presentAlert(e);
      }
    },
    async handleUpdateBusinessInfomation() {
      try {
        await this.setOpen(true);
        const isUpdateBusinessInfomation = this.handleValidateFormSubmit([1, 2]);
        if (isUpdateBusinessInfomation) {
          await apolloClient.mutate({
            mutation: updateCustomer,
            variables: {
              item: {
                id: this.customerDetailsEdit.id,
                name: this.form.companyName.value || '',
                profile_id: this.form.profile.id || null,
                account_number: this.customerDetailsEdit.account_number || '',
                credit_term: 0,
                customer_type_id: 2,
                delivery_instruction: this.customerDetailsEdit.delivery_instruction || '',
                halal_products: this.form.halal,
                cod: this.customerDetailsEdit.cod
              }
            }
          });
          await this.setOpen(false);
          this.$emit('handle-rerender-component', false);
        }
        await this.setOpen(false);
      } catch (e) {
        this.presentAlert(e);
      }
    },
    getDataFromPlaceFn(place) {
      const data = getDataFromPlace(place);
      this.placeData = data;
      this.addressCss = this.$refs.searchInput.value;
      // this.state = data.state;
      this.form.country.value = data.country;
      this.form.state.value = data.state;
      this.form.city.value = data.city;
      this.form.postalCode.value = data.postalCode;
      this.form.street.value = data.road;
      this.latlng = data.latlng;
      //reset validate error
      this.handleResetValidate();
    },
    handleResetValidate() {
      this.form.country.isError = false;
      this.form.state.isError = false;
      this.form.city.isError = false;
      this.form.postalCode.isError = false;
      this.form.street.isError = false;
    },
    handleValidateFormSubmit(params) {
      for (let value of params) {
        this.validateForm(value);
      }
      let isAddAddress = true;
      for (let i in this.form) {
        if (this.form[i].isError === true) {
          isAddAddress = false;
          break;
        }
      }
      return isAddAddress;
    },

    onSearch() {
      if (this.input.value === '') {
        this.input.focus();
        return;
      }
      google.maps.event.trigger(this.input, 'focus', {});
      google.maps.event.trigger(this.input, 'keydown', {
        keyCode: 40, // arrow down
        stopPropagation: () => {}, // because these get called
        preventDefault: () => {}
      });
      google.maps.event.trigger(this.input, 'keydown', { keyCode: 13 }); // enter
    },
    //show message error
    async presentAlert(params) {
      await this.setOpen(false);
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        header: 'Alert',
        message: params,
        buttons: [this.$t('OK')]
      });
      await alert.present();
    }
  }
});
</script>
<style src="../styles/ModalEditBusinessInformation.scss" lang="scss" scoped></style>
<style lang="scss" src="@/assets/css/newdesign.scss" scoped></style>
